.desktopContainer {
  flex: auto;
  justify-content: right;
  padding-top: 36px;
  margin-left: 42px;
}

.mobileContainer {
  padding-top: 36px;
}
